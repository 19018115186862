<template>
  <b-modal
      :id="modalId"
      size="md"
      :title="modalTitle"
      centered
      scrollable
      title-class="font-22"
      hide-footer
      @shown="setData"
  >
    <b-form
        class="purchase-form"
        @submit="handleSubmit"
    >
      <b-form-group
          label-cols-md="3"
          label-align="right"
          label-for="code"
      >
        <template v-slot:label>
          Code <span class="text-danger">*</span>
        </template>
        <b-form-input
            id="code"
            v-model="form.code"
            type="text"
            placeholder="Enter code"
            required
        />
      </b-form-group>
      <b-form-group
          label-cols-md="3"
          label-align="right"
          label-for="amount"
      >
        <template v-slot:label>
          Amount <span class="text-danger">*</span>
        </template>
        <b-form-input
            id="amount"
            v-model="form.amount"
            type="number"
            placeholder="Enter amount"
            required
        />
      </b-form-group>
      <b-form-group
          label-cols-md="3"
          label-align="right"
          label-for="number-of-uses"
      >
        <template v-slot:label>
          Number of uses <span class="text-danger">*</span>
        </template>
        <b-form-input
            id="number-of-uses"
            v-model="form.uses_left"
            type="number"
            placeholder="Enter number of uses"
            required
        />
      </b-form-group>
      <b-form-group
          label-cols-md="3"
          label-align="right"
          label-for="date_from"
      >
        <template v-slot:label>
          Date from <span class="text-danger">*</span>
        </template>
        <b-form-input
            id="date_from"
            v-model="form.date_from"
            type="date"
            required
        />
      </b-form-group>
      <b-form-group
          label-cols-md="3"
          label-align="right"
          label-for="date_to"
      >
        <template v-slot:label>
          Date to <span class="text-danger">*</span>
        </template>
        <b-form-input
            id="date_to"
            v-model="form.date_to"
            type="date"
            required
        />
      </b-form-group>
      <div class="d-flex justify-content-end">
        <ModalButton :buttonText="buttonText" />
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import {validateForm} from '@/utils/validation';
import {FORM_MODE} from '@/utils/constants';
import ModalButton from '@/components/modal-button';

export default {
  name: 'purchaseModalForm',
  props: {
    'modalId': {
      type: String,
      required: true
    },
    'initialData': Object,
    formMode: String,
    modalTitle: String
  },
  components: {
    ModalButton,
  },
  data() {
    return {
      form: {
        code: null,
        amount: null,
        uses_left: null,
        date_from: null,
        date_to: null,
      }
    }
  },
  computed: {
    buttonText() {
      return this.formMode === FORM_MODE.CREATE ? 'Add' : 'Save';
    }
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();
      if (validateForm(this.form)) {
        this.$emit('onSubmit', this.form);
      }
    },
    setData() {
      this.form.code = this.initialData ? this.initialData.code : null;
      this.form.amount = this.initialData ? this.initialData.amount : null;
      this.form.uses_left = this.initialData ? this.initialData.uses_left : null;
      this.form.date_from = this.initialData ? this.initialData.date_from : null;
      this.form.date_to = this.initialData ? this.initialData.date_to : null;
    }
  },
}
</script>

<style lang="scss">
.survey-form {
  .custom-control-label {
    vertical-align: middle;
  }
}

</style>
