<template>
    <layout class="leaderboard" :loading="loading">
        <ModalForm
                :modalId="modalId"
                :formMode="formMode"
                :initialData="currentRow"
                :modalTitle="modalTitle"
                @onSubmit="handleFormSubmit"
        />
        <div class="row mb-4">
            <div class="col-6">
                <h2>Codes management</h2>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <b-form-input
                        id="month"
                        v-model="search"
                        type="text"
                        class="col-4"
                        placeholder="Search"
                />
                <b-button
                        class="ml-3 btn-small"
                        variant="primary"
                        @click="handleAdd()"
                >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    Add code
                </b-button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body p-0">
                        <table class="table mb-0">
                            <thead>
                            <th style="width: 50px">#</th>
                            <th class="text-left">Code</th>
                            <th class="text-right">Amount</th>
                            <th class="text-right">Uses</th>
                            <th class="text-right">Uses left</th>
                            <th class="text-right">Used</th>
                            <th>Date from</th>
                            <th>Date to</th>
                            <th style="width: 50px">Actions</th>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in list" :key="item.id">
                                <td>{{index + 1}}</td>
                                <td class="text-left">{{ item.code }}</td>
                                <td class="text-right">{{ item.amount }}</td>
                                <td class="text-right">{{ item.uses_left }}</td>
                                <td class="text-right">{{ item.codes ? item.uses_left-item.codes.length : item.uses_left }}</td>
                                <td class="text-right">{{ item.codes ? item.codes.length : 0 }}</td>
                                <td>{{ moment(item.date_from).format('DD.MM.YYYY') }}</td>
                                <td>{{ moment(item.date_to).format('DD.MM.YYYY') }}</td>
                                <td>
                                    <div class="d-flex justify-content-center">
                                        <b-button
                                                style="margin-top: -0.5rem;"
                                                variant="outline-primary"
                                                class="shadow-none border-0 p-2 mt--2 edit-btn"
                                                @click="handleEdit(item)"
                                        >
                                            <span class="fa fa-pen"></span>
                                        </b-button>
                                        <b-button
                                                style="margin-top: -0.5rem;"
                                                variant="outline-danger"
                                                class="shadow-none border-0 p-2 mt--2 edit-btn"
                                                @click="handleDelete(item.id)"
                                        >
                                            <span class="fa fa-trash"></span>
                                        </b-button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <EmptyPage v-if="!list.length" />
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>
<script>

    import {FORM_MODE} from "@/utils/constants";
    import {convertObjectToFormData} from "@/utils/converters";
    import ModalForm from './codes-managment-modal'
    import moment from 'moment'
    import EmptyPage from '@/components/empty-page';

    /**
     * Editors component
     */
    export default {
        components: {
            ModalForm,
            EmptyPage
        },
        data() {
            return {
                leaderboards: [],
                modalId: 'request-modal',
                modalTitle: '',
                currentRow: null,
                formMode: '',
                moment,
                search: ''
            };
        },
        mounted() {
            this.$store.dispatch('profile/getProfile');
            this.$store.dispatch('codesManagement/getCodes');
        },
        methods: {
            handleAdd() {
                this.modalTitle = 'Add new code';
                this.currentRow = null;
                this.formMode = FORM_MODE.CREATE;
                this.$bvModal.show(this.modalId);
            },
            handleEdit(row) {
                this.currentRow = row;
                this.formMode = FORM_MODE.EDIT;
                this.modalTitle = 'Update code';
                this.$bvModal.show(this.modalId);
            },
            handleDelete(id) {
                this.$bvModal.msgBoxConfirm('Delete this item?', {
                    title: 'Please Confirm',
                    okVariant: 'danger',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                    .then(async (value) => {
                        if (value) {
                            await this.$store.dispatch('codesManagement/deleteCode', {
                                data: convertObjectToFormData({_method: 'DELETE'}),
                                id
                            });
                            await this.$store.dispatch('codesManagement/getCodes');
                        }
                    });
            },
            async handleFormSubmit(data) {
                let isSuccessLoad = false;
                await this.$store.dispatch('global/changeLoadingValue', true);
                if (this.formMode === FORM_MODE.CREATE) {
                    await this.$store.dispatch('codesManagement/createCode', convertObjectToFormData(data))
                        .then((res) => {
                            if (res?.code === 200) {
                                isSuccessLoad = true;
                            }
                        });
                }
                if (this.formMode === FORM_MODE.EDIT) {
                    await this.$store.dispatch('codesManagement/updateCode', {
                        id: this.currentRow.id,
                        data: convertObjectToFormData({
                            ...data,
                            _method: 'PUT'
                        })
                    })
                        .then((res) => {
                            if (res?.code === 200) {
                                isSuccessLoad = true;
                            }
                        });
                }
                this.$store.dispatch('global/changeLoadingValue', false);
                if (isSuccessLoad) {
                    await this.$store.dispatch('codesManagement/getCodes');
                    this.$bvModal.hide(this.modalId);
                }
            },
        },
        computed: {
            list() {
                const list = [...this.$store.getters['codesManagement/codeList']];
                return list.filter(e => e.code?.toLowerCase().indexOf(this.search.toLowerCase()) > -1).sort((a, b) => a.id > b.id ? -1 : 1);
            },
            loading() {
                return this.$store.state.codesManagement.loading;
            }
        }
    };
</script>
<style lang="scss" scoped>
    .leaderboard table img {
        max-width: 200px;
    }

    table, thead, tbody {
        width: 100%;

        th, td{
            font-size: 14px;
            text-align: center;
        }
    }

</style>