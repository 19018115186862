<template>
  <b-button
    :type="type"
    :variant="variant"
    :disabled="loading"
  >
    <b-spinner small v-if="loading" label="Spinning"></b-spinner>
    {{ buttonText }}
  </b-button>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: 'primary'
    },
    type: {
      type: String,
      default: 'submit'
    },
  },
  computed: {
    loading() {
      return this.$store.state.global.loading
    }
  },
  async created() {
    await this.$store.dispatch('global/changeLoadingValue', false);
  }
}
</script>

<style scoped>

</style>